/*
File generated by js-routes 1.4.14
Based on Rails 6.1.7 routes of Content::Application
 */

(function() {
  var DeprecatedGlobbingBehavior, NodeTypes, ParameterMissing, ReservedOptions, SpecialOptionsKey, UriEncoderSegmentRegex, Utils, error, result,
    hasProp = {}.hasOwnProperty,
    slice = [].slice;

  ParameterMissing = function(message, fileName, lineNumber) {
    var instance;
    instance = new Error(message, fileName, lineNumber);
    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(instance, Object.getPrototypeOf(this));
    } else {
      instance.__proto__ = this.__proto__;
    }
    if (Error.captureStackTrace) {
      Error.captureStackTrace(instance, ParameterMissing);
    }
    return instance;
  };

  ParameterMissing.prototype = Object.create(Error.prototype, {
    constructor: {
      value: Error,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });

  if (Object.setPrototypeOf) {
    Object.setPrototypeOf(ParameterMissing, Error);
  } else {
    ParameterMissing.__proto__ = Error;
  }

  NodeTypes = {"GROUP":1,"CAT":2,"SYMBOL":3,"OR":4,"STAR":5,"LITERAL":6,"SLASH":7,"DOT":8};

  DeprecatedGlobbingBehavior = false;

  SpecialOptionsKey = "_options";

  UriEncoderSegmentRegex = /[^a-zA-Z0-9\-\._~!\$&'\(\)\*\+,;=:@]/g;

  ReservedOptions = ['anchor', 'trailing_slash', 'subdomain', 'host', 'port', 'protocol'];

  Utils = {
    configuration: {
      prefix: "",
      default_url_options: {},
      special_options_key: "_options",
      serializer: null
    },
    default_serializer: function(object, prefix) {
      var element, i, j, key, len, prop, s;
      if (prefix == null) {
        prefix = null;
      }
      if (object == null) {
        return "";
      }
      if (!prefix && !(this.get_object_type(object) === "object")) {
        throw new Error("Url parameters should be a javascript hash");
      }
      s = [];
      switch (this.get_object_type(object)) {
        case "array":
          for (i = j = 0, len = object.length; j < len; i = ++j) {
            element = object[i];
            s.push(this.default_serializer(element, prefix + "[]"));
          }
          break;
        case "object":
          for (key in object) {
            if (!hasProp.call(object, key)) continue;
            prop = object[key];
            if ((prop == null) && (prefix != null)) {
              prop = "";
            }
            if (prop != null) {
              if (prefix != null) {
                key = prefix + "[" + key + "]";
              }
              s.push(this.default_serializer(prop, key));
            }
          }
          break;
        default:
          if (object != null) {
            s.push((encodeURIComponent(prefix.toString())) + "=" + (encodeURIComponent(object.toString())));
          }
      }
      if (!s.length) {
        return "";
      }
      return s.join("&");
    },
    serialize: function(object) {
      var custom_serializer;
      custom_serializer = this.configuration.serializer;
      if ((custom_serializer != null) && this.get_object_type(custom_serializer) === "function") {
        return custom_serializer(object);
      } else {
        return this.default_serializer(object);
      }
    },
    clean_path: function(path) {
      var last_index;
      path = path.split("://");
      last_index = path.length - 1;
      path[last_index] = path[last_index].replace(/\/+/g, "/");
      return path.join("://");
    },
    extract_options: function(number_of_params, args) {
      var last_el, options;
      last_el = args[args.length - 1];
      if ((args.length > number_of_params && last_el === void 0) || ((last_el != null) && "object" === this.get_object_type(last_el) && !this.looks_like_serialized_model(last_el))) {
        options = args.pop() || {};
        delete options[this.configuration.special_options_key];
        return options;
      } else {
        return {};
      }
    },
    looks_like_serialized_model: function(object) {
      return !object[this.configuration.special_options_key] && ("id" in object || "to_param" in object);
    },
    path_identifier: function(object) {
      var property;
      if (object === 0) {
        return "0";
      }
      if (!object) {
        return "";
      }
      property = object;
      if (this.get_object_type(object) === "object") {
        if ("to_param" in object) {
          if (object.to_param == null) {
            throw new ParameterMissing("Route parameter missing: to_param");
          }
          property = object.to_param;
        } else if ("id" in object) {
          if (object.id == null) {
            throw new ParameterMissing("Route parameter missing: id");
          }
          property = object.id;
        } else {
          property = object;
        }
        if (this.get_object_type(property) === "function") {
          property = property.call(object);
        }
      }
      return property.toString();
    },
    clone: function(obj) {
      var attr, copy, key;
      if ((obj == null) || "object" !== this.get_object_type(obj)) {
        return obj;
      }
      copy = obj.constructor();
      for (key in obj) {
        if (!hasProp.call(obj, key)) continue;
        attr = obj[key];
        copy[key] = attr;
      }
      return copy;
    },
    merge: function() {
      var tap, xs;
      xs = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      tap = function(o, fn) {
        fn(o);
        return o;
      };
      if ((xs != null ? xs.length : void 0) > 0) {
        return tap({}, function(m) {
          var j, k, len, results, v, x;
          results = [];
          for (j = 0, len = xs.length; j < len; j++) {
            x = xs[j];
            results.push((function() {
              var results1;
              results1 = [];
              for (k in x) {
                v = x[k];
                results1.push(m[k] = v);
              }
              return results1;
            })());
          }
          return results;
        });
      }
    },
    normalize_options: function(parts, required_parts, default_options, actual_parameters) {
      var i, j, key, len, options, part, parts_options, result, route_parts, url_parameters, use_all_parts, value;
      options = this.extract_options(parts.length, actual_parameters);
      if (actual_parameters.length > parts.length) {
        throw new Error("Too many parameters provided for path");
      }
      use_all_parts = actual_parameters.length > required_parts.length;
      parts_options = {};
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        use_all_parts = true;
        if (this.indexOf(parts, key) >= 0) {
          parts_options[key] = value;
        }
      }
      options = this.merge(this.configuration.default_url_options, default_options, options);
      result = {};
      url_parameters = {};
      result['url_parameters'] = url_parameters;
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        value = options[key];
        if (this.indexOf(ReservedOptions, key) >= 0) {
          result[key] = value;
        } else {
          url_parameters[key] = value;
        }
      }
      route_parts = use_all_parts ? parts : required_parts;
      i = 0;
      for (j = 0, len = route_parts.length; j < len; j++) {
        part = route_parts[j];
        if (i < actual_parameters.length) {
          if (!parts_options.hasOwnProperty(part)) {
            url_parameters[part] = actual_parameters[i];
            ++i;
          }
        }
      }
      return result;
    },
    build_route: function(parts, required_parts, default_options, route, full_url, args) {
      var options, parameters, result, url, url_params;
      args = Array.prototype.slice.call(args);
      options = this.normalize_options(parts, required_parts, default_options, args);
      parameters = options['url_parameters'];
      result = "" + (this.get_prefix()) + (this.visit(route, parameters));
      url = Utils.clean_path(result);
      if (options['trailing_slash'] === true) {
        url = url.replace(/(.*?)[\/]?$/, "$1/");
      }
      if ((url_params = this.serialize(parameters)).length) {
        url += "?" + url_params;
      }
      url += options.anchor ? "#" + options.anchor : "";
      if (full_url) {
        url = this.route_url(options) + url;
      }
      return url;
    },
    visit: function(route, parameters, optional) {
      var left, left_part, right, right_part, type, value;
      if (optional == null) {
        optional = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return this.visit(left, parameters, true);
        case NodeTypes.STAR:
          return this.visit_globbing(left, parameters, true);
        case NodeTypes.LITERAL:
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
          return left;
        case NodeTypes.CAT:
          left_part = this.visit(left, parameters, optional);
          right_part = this.visit(right, parameters, optional);
          if (optional && ((this.is_optional_node(left[0]) && !left_part) || ((this.is_optional_node(right[0])) && !right_part))) {
            return "";
          }
          return "" + left_part + right_part;
        case NodeTypes.SYMBOL:
          value = parameters[left];
          delete parameters[left];
          if (value != null) {
            return this.encode_segment(this.path_identifier(value));
          }
          if (optional) {
            return "";
          } else {
            throw new ParameterMissing("Route parameter missing: " + left);
          }
          break;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    encode_segment: function(segment) {
      return segment.replace(UriEncoderSegmentRegex, function(str) {
        return encodeURIComponent(str);
      });
    },
    is_optional_node: function(node) {
      return this.indexOf([NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT], node) >= 0;
    },
    build_path_spec: function(route, wildcard) {
      var left, right, type;
      if (wildcard == null) {
        wildcard = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return "(" + (this.build_path_spec(left)) + ")";
        case NodeTypes.CAT:
          return "" + (this.build_path_spec(left)) + (this.build_path_spec(right));
        case NodeTypes.STAR:
          return this.build_path_spec(left, true);
        case NodeTypes.SYMBOL:
          if (wildcard === true) {
            return "" + (left[0] === '*' ? '' : '*') + left;
          } else {
            return ":" + left;
          }
          break;
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
        case NodeTypes.LITERAL:
          return left;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    visit_globbing: function(route, parameters, optional) {
      var left, right, type, value;
      type = route[0], left = route[1], right = route[2];
      value = parameters[left];
      delete parameters[left];
      if (value == null) {
        return this.visit(route, parameters, optional);
      }
      value = (function() {
        switch (this.get_object_type(value)) {
          case "array":
            return value.join("/");
          default:
            return value;
        }
      }).call(this);
      if (DeprecatedGlobbingBehavior) {
        return this.path_identifier(value);
      } else {
        return encodeURI(this.path_identifier(value));
      }
    },
    get_prefix: function() {
      var prefix;
      prefix = this.configuration.prefix;
      if (prefix !== "") {
        prefix = (prefix.match("/$") ? prefix : prefix + "/");
      }
      return prefix;
    },
    route: function(parts_table, default_options, route_spec, full_url) {
      var j, len, part, parts, path_fn, ref, required, required_parts;
      required_parts = [];
      parts = [];
      for (j = 0, len = parts_table.length; j < len; j++) {
        ref = parts_table[j], part = ref[0], required = ref[1];
        parts.push(part);
        if (required) {
          required_parts.push(part);
        }
      }
      path_fn = function() {
        return Utils.build_route(parts, required_parts, default_options, route_spec, full_url, arguments);
      };
      path_fn.required_params = required_parts;
      path_fn.toString = function() {
        return Utils.build_path_spec(route_spec);
      };
      return path_fn;
    },
    route_url: function(route_defaults) {
      var hostname, port, protocol, subdomain;
      if (typeof route_defaults === 'string') {
        return route_defaults;
      }
      hostname = route_defaults.host || Utils.current_host();
      if (!hostname) {
        return '';
      }
      subdomain = route_defaults.subdomain ? route_defaults.subdomain + '.' : '';
      protocol = route_defaults.protocol || Utils.current_protocol();
      port = route_defaults.port || (!route_defaults.host ? Utils.current_port() : void 0);
      port = port ? ":" + port : '';
      return protocol + "://" + subdomain + hostname + port;
    },
    has_location: function() {
      return (typeof window !== "undefined" && window !== null ? window.location : void 0) != null;
    },
    current_host: function() {
      if (this.has_location()) {
        return window.location.hostname;
      } else {
        return null;
      }
    },
    current_protocol: function() {
      if (this.has_location() && window.location.protocol !== '') {
        return window.location.protocol.replace(/:$/, '');
      } else {
        return 'http';
      }
    },
    current_port: function() {
      if (this.has_location() && window.location.port !== '') {
        return window.location.port;
      } else {
        return '';
      }
    },
    _classToTypeCache: null,
    _classToType: function() {
      var j, len, name, ref;
      if (this._classToTypeCache != null) {
        return this._classToTypeCache;
      }
      this._classToTypeCache = {};
      ref = "Boolean Number String Function Array Date RegExp Object Error".split(" ");
      for (j = 0, len = ref.length; j < len; j++) {
        name = ref[j];
        this._classToTypeCache["[object " + name + "]"] = name.toLowerCase();
      }
      return this._classToTypeCache;
    },
    get_object_type: function(obj) {
      if (this.jQuery && (this.jQuery.type != null)) {
        return this.jQuery.type(obj);
      }
      if (obj == null) {
        return "" + obj;
      }
      if (typeof obj === "object" || typeof obj === "function") {
        return this._classToType()[Object.prototype.toString.call(obj)] || "object";
      } else {
        return typeof obj;
      }
    },
    indexOf: function(array, element) {
      if (Array.prototype.indexOf) {
        return array.indexOf(element);
      } else {
        return this.indexOfImplementation(array, element);
      }
    },
    indexOfImplementation: function(array, element) {
      var el, i, j, len, result;
      result = -1;
      for (i = j = 0, len = array.length; j < len; i = ++j) {
        el = array[i];
        if (el === element) {
          result = i;
        }
      }
      return result;
    },
    namespace: function(root, namespace, routes) {
      var index, j, len, part, parts;
      parts = namespace ? namespace.split(".") : [];
      if (parts.length === 0) {
        return routes;
      }
      for (index = j = 0, len = parts.length; j < len; index = ++j) {
        part = parts[index];
        if (index < parts.length - 1) {
          root = (root[part] || (root[part] = {}));
        } else {
          return root[part] = routes;
        }
      }
    },
    configure: function(new_config) {
      return this.configuration = this.merge(this.configuration, new_config);
    },
    config: function() {
      return this.clone(this.configuration);
    },
    make: function() {
      var routes;
      routes = {
// api_v1_activities => /api/v1/activities(.:format)
  // function(options)
  api_v1_activities_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"activities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_breadcrumb_index => /api/v1/breadcrumb(.:format)
  // function(options)
  api_v1_breadcrumb_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"breadcrumb",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_grades => /api/v1/grades(.:format)
  // function(options)
  api_v1_grades_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"grades",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_literacy_toolbox_index => /api/v1/literacy_toolbox(.:format)
  // function(options)
  api_v1_literacy_toolbox_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"literacy_toolbox",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_resources => /api/v1/resources(.:format)
  // function(options)
  api_v1_resources_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"resources",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]])}
;
      routes.configure = function(config) {
        return Utils.configure(config);
      };
      routes.config = function() {
        return Utils.config();
      };
      routes.default_serializer = function(object, prefix) {
        return Utils.default_serializer(object, prefix);
      };
      return Object.assign({
        "default": routes
      }, routes);
    }
  };

  result = Utils.make();

  if (typeof define === "function" && define.amd) {
    define([], function() {
      return result;
    });
  } else if (typeof module !== "undefined" && module !== null) {
    try {
      module.exports = result;
    } catch (error1) {
      error = error1;
      if (error.name !== 'TypeError') {
        throw error;
      }
    }
  } else {
    Utils.namespace(this, "OdellApiRoutes", result);
  }

  return result;

}).call(this);

/*
File generated by js-routes 1.4.14
Based on Rails 6.1.7 routes of Content::Application
 */

(function() {
  var DeprecatedGlobbingBehavior, NodeTypes, ParameterMissing, ReservedOptions, SpecialOptionsKey, UriEncoderSegmentRegex, Utils, error, result,
    hasProp = {}.hasOwnProperty,
    slice = [].slice;

  ParameterMissing = function(message, fileName, lineNumber) {
    var instance;
    instance = new Error(message, fileName, lineNumber);
    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(instance, Object.getPrototypeOf(this));
    } else {
      instance.__proto__ = this.__proto__;
    }
    if (Error.captureStackTrace) {
      Error.captureStackTrace(instance, ParameterMissing);
    }
    return instance;
  };

  ParameterMissing.prototype = Object.create(Error.prototype, {
    constructor: {
      value: Error,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });

  if (Object.setPrototypeOf) {
    Object.setPrototypeOf(ParameterMissing, Error);
  } else {
    ParameterMissing.__proto__ = Error;
  }

  NodeTypes = {"GROUP":1,"CAT":2,"SYMBOL":3,"OR":4,"STAR":5,"LITERAL":6,"SLASH":7,"DOT":8};

  DeprecatedGlobbingBehavior = false;

  SpecialOptionsKey = "_options";

  UriEncoderSegmentRegex = /[^a-zA-Z0-9\-\._~!\$&'\(\)\*\+,;=:@]/g;

  ReservedOptions = ['anchor', 'trailing_slash', 'subdomain', 'host', 'port', 'protocol'];

  Utils = {
    configuration: {
      prefix: "",
      default_url_options: {},
      special_options_key: "_options",
      serializer: null
    },
    default_serializer: function(object, prefix) {
      var element, i, j, key, len, prop, s;
      if (prefix == null) {
        prefix = null;
      }
      if (object == null) {
        return "";
      }
      if (!prefix && !(this.get_object_type(object) === "object")) {
        throw new Error("Url parameters should be a javascript hash");
      }
      s = [];
      switch (this.get_object_type(object)) {
        case "array":
          for (i = j = 0, len = object.length; j < len; i = ++j) {
            element = object[i];
            s.push(this.default_serializer(element, prefix + "[]"));
          }
          break;
        case "object":
          for (key in object) {
            if (!hasProp.call(object, key)) continue;
            prop = object[key];
            if ((prop == null) && (prefix != null)) {
              prop = "";
            }
            if (prop != null) {
              if (prefix != null) {
                key = prefix + "[" + key + "]";
              }
              s.push(this.default_serializer(prop, key));
            }
          }
          break;
        default:
          if (object != null) {
            s.push((encodeURIComponent(prefix.toString())) + "=" + (encodeURIComponent(object.toString())));
          }
      }
      if (!s.length) {
        return "";
      }
      return s.join("&");
    },
    serialize: function(object) {
      var custom_serializer;
      custom_serializer = this.configuration.serializer;
      if ((custom_serializer != null) && this.get_object_type(custom_serializer) === "function") {
        return custom_serializer(object);
      } else {
        return this.default_serializer(object);
      }
    },
    clean_path: function(path) {
      var last_index;
      path = path.split("://");
      last_index = path.length - 1;
      path[last_index] = path[last_index].replace(/\/+/g, "/");
      return path.join("://");
    },
    extract_options: function(number_of_params, args) {
      var last_el, options;
      last_el = args[args.length - 1];
      if ((args.length > number_of_params && last_el === void 0) || ((last_el != null) && "object" === this.get_object_type(last_el) && !this.looks_like_serialized_model(last_el))) {
        options = args.pop() || {};
        delete options[this.configuration.special_options_key];
        return options;
      } else {
        return {};
      }
    },
    looks_like_serialized_model: function(object) {
      return !object[this.configuration.special_options_key] && ("id" in object || "to_param" in object);
    },
    path_identifier: function(object) {
      var property;
      if (object === 0) {
        return "0";
      }
      if (!object) {
        return "";
      }
      property = object;
      if (this.get_object_type(object) === "object") {
        if ("to_param" in object) {
          if (object.to_param == null) {
            throw new ParameterMissing("Route parameter missing: to_param");
          }
          property = object.to_param;
        } else if ("id" in object) {
          if (object.id == null) {
            throw new ParameterMissing("Route parameter missing: id");
          }
          property = object.id;
        } else {
          property = object;
        }
        if (this.get_object_type(property) === "function") {
          property = property.call(object);
        }
      }
      return property.toString();
    },
    clone: function(obj) {
      var attr, copy, key;
      if ((obj == null) || "object" !== this.get_object_type(obj)) {
        return obj;
      }
      copy = obj.constructor();
      for (key in obj) {
        if (!hasProp.call(obj, key)) continue;
        attr = obj[key];
        copy[key] = attr;
      }
      return copy;
    },
    merge: function() {
      var tap, xs;
      xs = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      tap = function(o, fn) {
        fn(o);
        return o;
      };
      if ((xs != null ? xs.length : void 0) > 0) {
        return tap({}, function(m) {
          var j, k, len, results, v, x;
          results = [];
          for (j = 0, len = xs.length; j < len; j++) {
            x = xs[j];
            results.push((function() {
              var results1;
              results1 = [];
              for (k in x) {
                v = x[k];
                results1.push(m[k] = v);
              }
              return results1;
            })());
          }
          return results;
        });
      }
    },
    normalize_options: function(parts, required_parts, default_options, actual_parameters) {
      var i, j, key, len, options, part, parts_options, result, route_parts, url_parameters, use_all_parts, value;
      options = this.extract_options(parts.length, actual_parameters);
      if (actual_parameters.length > parts.length) {
        throw new Error("Too many parameters provided for path");
      }
      use_all_parts = actual_parameters.length > required_parts.length;
      parts_options = {};
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        use_all_parts = true;
        if (this.indexOf(parts, key) >= 0) {
          parts_options[key] = value;
        }
      }
      options = this.merge(this.configuration.default_url_options, default_options, options);
      result = {};
      url_parameters = {};
      result['url_parameters'] = url_parameters;
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        value = options[key];
        if (this.indexOf(ReservedOptions, key) >= 0) {
          result[key] = value;
        } else {
          url_parameters[key] = value;
        }
      }
      route_parts = use_all_parts ? parts : required_parts;
      i = 0;
      for (j = 0, len = route_parts.length; j < len; j++) {
        part = route_parts[j];
        if (i < actual_parameters.length) {
          if (!parts_options.hasOwnProperty(part)) {
            url_parameters[part] = actual_parameters[i];
            ++i;
          }
        }
      }
      return result;
    },
    build_route: function(parts, required_parts, default_options, route, full_url, args) {
      var options, parameters, result, url, url_params;
      args = Array.prototype.slice.call(args);
      options = this.normalize_options(parts, required_parts, default_options, args);
      parameters = options['url_parameters'];
      result = "" + (this.get_prefix()) + (this.visit(route, parameters));
      url = Utils.clean_path(result);
      if (options['trailing_slash'] === true) {
        url = url.replace(/(.*?)[\/]?$/, "$1/");
      }
      if ((url_params = this.serialize(parameters)).length) {
        url += "?" + url_params;
      }
      url += options.anchor ? "#" + options.anchor : "";
      if (full_url) {
        url = this.route_url(options) + url;
      }
      return url;
    },
    visit: function(route, parameters, optional) {
      var left, left_part, right, right_part, type, value;
      if (optional == null) {
        optional = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return this.visit(left, parameters, true);
        case NodeTypes.STAR:
          return this.visit_globbing(left, parameters, true);
        case NodeTypes.LITERAL:
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
          return left;
        case NodeTypes.CAT:
          left_part = this.visit(left, parameters, optional);
          right_part = this.visit(right, parameters, optional);
          if (optional && ((this.is_optional_node(left[0]) && !left_part) || ((this.is_optional_node(right[0])) && !right_part))) {
            return "";
          }
          return "" + left_part + right_part;
        case NodeTypes.SYMBOL:
          value = parameters[left];
          delete parameters[left];
          if (value != null) {
            return this.encode_segment(this.path_identifier(value));
          }
          if (optional) {
            return "";
          } else {
            throw new ParameterMissing("Route parameter missing: " + left);
          }
          break;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    encode_segment: function(segment) {
      return segment.replace(UriEncoderSegmentRegex, function(str) {
        return encodeURIComponent(str);
      });
    },
    is_optional_node: function(node) {
      return this.indexOf([NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT], node) >= 0;
    },
    build_path_spec: function(route, wildcard) {
      var left, right, type;
      if (wildcard == null) {
        wildcard = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return "(" + (this.build_path_spec(left)) + ")";
        case NodeTypes.CAT:
          return "" + (this.build_path_spec(left)) + (this.build_path_spec(right));
        case NodeTypes.STAR:
          return this.build_path_spec(left, true);
        case NodeTypes.SYMBOL:
          if (wildcard === true) {
            return "" + (left[0] === '*' ? '' : '*') + left;
          } else {
            return ":" + left;
          }
          break;
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
        case NodeTypes.LITERAL:
          return left;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    visit_globbing: function(route, parameters, optional) {
      var left, right, type, value;
      type = route[0], left = route[1], right = route[2];
      value = parameters[left];
      delete parameters[left];
      if (value == null) {
        return this.visit(route, parameters, optional);
      }
      value = (function() {
        switch (this.get_object_type(value)) {
          case "array":
            return value.join("/");
          default:
            return value;
        }
      }).call(this);
      if (DeprecatedGlobbingBehavior) {
        return this.path_identifier(value);
      } else {
        return encodeURI(this.path_identifier(value));
      }
    },
    get_prefix: function() {
      var prefix;
      prefix = this.configuration.prefix;
      if (prefix !== "") {
        prefix = (prefix.match("/$") ? prefix : prefix + "/");
      }
      return prefix;
    },
    route: function(parts_table, default_options, route_spec, full_url) {
      var j, len, part, parts, path_fn, ref, required, required_parts;
      required_parts = [];
      parts = [];
      for (j = 0, len = parts_table.length; j < len; j++) {
        ref = parts_table[j], part = ref[0], required = ref[1];
        parts.push(part);
        if (required) {
          required_parts.push(part);
        }
      }
      path_fn = function() {
        return Utils.build_route(parts, required_parts, default_options, route_spec, full_url, arguments);
      };
      path_fn.required_params = required_parts;
      path_fn.toString = function() {
        return Utils.build_path_spec(route_spec);
      };
      return path_fn;
    },
    route_url: function(route_defaults) {
      var hostname, port, protocol, subdomain;
      if (typeof route_defaults === 'string') {
        return route_defaults;
      }
      hostname = route_defaults.host || Utils.current_host();
      if (!hostname) {
        return '';
      }
      subdomain = route_defaults.subdomain ? route_defaults.subdomain + '.' : '';
      protocol = route_defaults.protocol || Utils.current_protocol();
      port = route_defaults.port || (!route_defaults.host ? Utils.current_port() : void 0);
      port = port ? ":" + port : '';
      return protocol + "://" + subdomain + hostname + port;
    },
    has_location: function() {
      return (typeof window !== "undefined" && window !== null ? window.location : void 0) != null;
    },
    current_host: function() {
      if (this.has_location()) {
        return window.location.hostname;
      } else {
        return null;
      }
    },
    current_protocol: function() {
      if (this.has_location() && window.location.protocol !== '') {
        return window.location.protocol.replace(/:$/, '');
      } else {
        return 'http';
      }
    },
    current_port: function() {
      if (this.has_location() && window.location.port !== '') {
        return window.location.port;
      } else {
        return '';
      }
    },
    _classToTypeCache: null,
    _classToType: function() {
      var j, len, name, ref;
      if (this._classToTypeCache != null) {
        return this._classToTypeCache;
      }
      this._classToTypeCache = {};
      ref = "Boolean Number String Function Array Date RegExp Object Error".split(" ");
      for (j = 0, len = ref.length; j < len; j++) {
        name = ref[j];
        this._classToTypeCache["[object " + name + "]"] = name.toLowerCase();
      }
      return this._classToTypeCache;
    },
    get_object_type: function(obj) {
      if (this.jQuery && (this.jQuery.type != null)) {
        return this.jQuery.type(obj);
      }
      if (obj == null) {
        return "" + obj;
      }
      if (typeof obj === "object" || typeof obj === "function") {
        return this._classToType()[Object.prototype.toString.call(obj)] || "object";
      } else {
        return typeof obj;
      }
    },
    indexOf: function(array, element) {
      if (Array.prototype.indexOf) {
        return array.indexOf(element);
      } else {
        return this.indexOfImplementation(array, element);
      }
    },
    indexOfImplementation: function(array, element) {
      var el, i, j, len, result;
      result = -1;
      for (i = j = 0, len = array.length; j < len; i = ++j) {
        el = array[i];
        if (el === element) {
          result = i;
        }
      }
      return result;
    },
    namespace: function(root, namespace, routes) {
      var index, j, len, part, parts;
      parts = namespace ? namespace.split(".") : [];
      if (parts.length === 0) {
        return routes;
      }
      for (index = j = 0, len = parts.length; j < len; index = ++j) {
        part = parts[index];
        if (index < parts.length - 1) {
          root = (root[part] || (root[part] = {}));
        } else {
          return root[part] = routes;
        }
      }
    },
    configure: function(new_config) {
      return this.configuration = this.merge(this.configuration, new_config);
    },
    config: function() {
      return this.clone(this.configuration);
    },
    make: function() {
      var routes;
      routes = {
// api_v1_activities => /api/v1/activities(.:format)
  // function(options)
  api_v1_activities_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"activities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_breadcrumb_index => /api/v1/breadcrumb(.:format)
  // function(options)
  api_v1_breadcrumb_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"breadcrumb",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_grades => /api/v1/grades(.:format)
  // function(options)
  api_v1_grades_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"grades",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_literacy_toolbox_index => /api/v1/literacy_toolbox(.:format)
  // function(options)
  api_v1_literacy_toolbox_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"literacy_toolbox",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_resources => /api/v1/resources(.:format)
  // function(options)
  api_v1_resources_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"resources",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// cancel_user_registration => /register/cancel(.:format)
  // function(options)
  cancel_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"register",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// destroy_user_session => /logout(.:format)
  // function(options)
  destroy_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"logout",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// edit_rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format)
  // function(id, options)
  edit_rails_conductor_inbound_email_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_user_password => /password/edit(.:format)
  // function(options)
  edit_user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// edit_user_registration => /register/edit(.:format)
  // function(options)
  edit_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"register",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// export_to_csv_lcms_admin_bundle => /lcms/admin/bundles/:id/export_to_csv(.:format)
  // function(id, options)
  export_to_csv_lcms_admin_bundle_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lcms",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"bundles",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"export_to_csv",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// gdoc_mass_export_full_lcms_admin_bundles => /lcms/admin/bundles/gdoc_mass_export_full(.:format)
  // function(options)
  gdoc_mass_export_full_lcms_admin_bundles_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lcms",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"bundles",false],[2,[7,"/",false],[2,[6,"gdoc_mass_export_full",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// gdoc_mass_export_lcms_admin_bundle => /lcms/admin/bundles/:id/gdoc_mass_export(.:format)
  // function(id, options)
  gdoc_mass_export_lcms_admin_bundle_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lcms",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"bundles",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"gdoc_mass_export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// gdoc_mass_export_literacy_toolbox_lcms_admin_bundles => /lcms/admin/bundles/gdoc_mass_export_literacy_toolbox(.:format)
  // function(options)
  gdoc_mass_export_literacy_toolbox_lcms_admin_bundles_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lcms",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"bundles",false],[2,[7,"/",false],[2,[6,"gdoc_mass_export_literacy_toolbox",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// gdoc_mass_export_literacy_toolbox_status_lcms_admin_bundles => /lcms/admin/bundles/gdoc_mass_export_literacy_toolbox_status(.:format)
  // function(options)
  gdoc_mass_export_literacy_toolbox_status_lcms_admin_bundles_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lcms",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"bundles",false],[2,[7,"/",false],[2,[6,"gdoc_mass_export_literacy_toolbox_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// gdoc_mass_export_status_lcms_admin_bundles => /lcms/admin/bundles/gdoc_mass_export_status(.:format)
  // function(options)
  gdoc_mass_export_status_lcms_admin_bundles_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lcms",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"bundles",false],[2,[7,"/",false],[2,[6,"gdoc_mass_export_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// import_curriculum => /lcms/admin/curriculum/import(.:format)
  // function(options)
  import_curriculum_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lcms",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"curriculum",false],[2,[7,"/",false],[2,[6,"import",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// lcms_admin_bundles => /lcms/admin/bundles(.:format)
  // function(options)
  lcms_admin_bundles_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lcms",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"bundles",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// lcms_admin_bundles_unit_reader => /lcms/admin/bundles/unit-reader/:type/:id(.:format)
  // function(type, id, options)
  lcms_admin_bundles_unit_reader_path: Utils.route([["type",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lcms",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"bundles",false],[2,[7,"/",false],[2,[6,"unit-reader",false],[2,[7,"/",false],[2,[3,"type",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// lcms_admin_bundles_unit_reader_preview => /lcms/admin/bundles/unit-reader/:type/:id/preview(.:format)
  // function(type, id, options)
  lcms_admin_bundles_unit_reader_preview_path: Utils.route([["type",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lcms",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"bundles",false],[2,[7,"/",false],[2,[6,"unit-reader",false],[2,[7,"/",false],[2,[3,"type",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"preview",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// lcms_admin_export_users_csv => /lcms/admin/users/export-to-csv(.:format)
  // function(options)
  lcms_admin_export_users_csv_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lcms",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"export-to-csv",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// lcms_engine => /lcms
  // function(options)
  lcms_engine_path: Utils.route([], {}, [2,[7,"/",false],[6,"lcms",false]]),
// lcms_engine.new_user_confirmation => /lcms/users/confirmation/new(.:format)
  // function(options)
  lcms_engine_new_user_confirmation_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"confirmation",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// lcms_engine.user_confirmation => /lcms/users/confirmation(.:format)
  // function(options)
  lcms_engine_user_confirmation_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"confirmation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// lcms_engine.pdfjs => /lcms/pdfjs
  // function(options)
  lcms_engine_pdfjs_path: Utils.route([], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[6,"pdfjs",false]]),
// lcms_engine.export_document => /lcms/documents/:id/export(.:format)
  // function(id, options)
  lcms_engine_export_document_path: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// lcms_engine.export_status_document => /lcms/documents/:id/export/status(.:format)
  // function(id, options)
  lcms_engine_export_status_document_path: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"export",false],[2,[7,"/",false],[2,[6,"status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// lcms_engine.lti_document => /lcms/documents/:id/lti(.:format)
  // function(id, options)
  lcms_engine_lti_document_path: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"lti",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// lcms_engine.document => /lcms/documents/:id(.:format)
  // function(id, options)
  lcms_engine_document_path: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// lcms_engine.preview_pdf_material => /lcms/materials/:id/preview/pdf(.:format)
  // function(id, options)
  lcms_engine_preview_pdf_material_path: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"materials",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"preview",false],[2,[7,"/",false],[2,[6,"pdf",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// lcms_engine.preview_gdoc_material => /lcms/materials/:id/preview/gdoc(.:format)
  // function(id, options)
  lcms_engine_preview_gdoc_material_path: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"materials",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"preview",false],[2,[7,"/",false],[2,[6,"gdoc",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// lcms_engine.material => /lcms/materials/:id(.:format)
  // function(id, options)
  lcms_engine_material_path: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"materials",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// lcms_engine.resource => /lcms/resources/:id(.:format)
  // function(id, options)
  lcms_engine_resource_path: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"resources",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// lcms_engine.new_user_session => /lcms/users/sign_in(.:format)
  // function(options)
  lcms_engine_new_user_session_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// lcms_engine.user_session => /lcms/users/sign_in(.:format)
  // function(options)
  lcms_engine_user_session_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// lcms_engine.destroy_user_session => /lcms/users/sign_out(.:format)
  // function(options)
  lcms_engine_destroy_user_session_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_out",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// lcms_engine.new_user_password => /lcms/users/password/new(.:format)
  // function(options)
  lcms_engine_new_user_password_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// lcms_engine.edit_user_password => /lcms/users/password/edit(.:format)
  // function(options)
  lcms_engine_edit_user_password_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// lcms_engine.user_password => /lcms/users/password(.:format)
  // function(options)
  lcms_engine_user_password_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// lcms_engine.cancel_user_registration => /lcms/users/cancel(.:format)
  // function(options)
  lcms_engine_cancel_user_registration_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// lcms_engine.new_user_registration => /lcms/users/sign_up(.:format)
  // function(options)
  lcms_engine_new_user_registration_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_up",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// lcms_engine.edit_user_registration => /lcms/users/edit(.:format)
  // function(options)
  lcms_engine_edit_user_registration_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// lcms_engine.user_registration => /lcms/users(.:format)
  // function(options)
  lcms_engine_user_registration_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// lcms_engine.resque_server => /lcms/queue
  // function(options)
  lcms_engine_resque_server_path: Utils.route([], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[6,"queue",false]]),
// lcms_engine.admin => /lcms/admin(.:format)
  // function(options)
  lcms_engine_admin_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"admin",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// lcms_engine.admin_association_picker => /lcms/admin/association_picker(.:format)
  // function(options)
  lcms_engine_admin_association_picker_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"association_picker",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// lcms_engine.new_admin_resource_bulk_edits => /lcms/admin/resource_bulk_edits/new(.:format)
  // function(options)
  lcms_engine_new_admin_resource_bulk_edits_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"resource_bulk_edits",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// lcms_engine.admin_resource_bulk_edits => /lcms/admin/resource_bulk_edits(.:format)
  // function(options)
  lcms_engine_admin_resource_bulk_edits_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"resource_bulk_edits",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// lcms_engine.admin_resource_picker => /lcms/admin/resource_picker(.:format)
  // function(options)
  lcms_engine_admin_resource_picker_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"resource_picker",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// lcms_engine.export_to_lti_cc_admin_resource => /lcms/admin/resources/:id/export-lti-cc(.:format)
  // function(id, options)
  lcms_engine_export_to_lti_cc_admin_resource_path: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"resources",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"export-lti-cc",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// lcms_engine.bundle_admin_resource => /lcms/admin/resources/:id/bundle(.:format)
  // function(id, options)
  lcms_engine_bundle_admin_resource_path: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"resources",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"bundle",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// lcms_engine.admin_resources => /lcms/admin/resources(.:format)
  // function(options)
  lcms_engine_admin_resources_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"resources",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// lcms_engine.new_admin_resource => /lcms/admin/resources/new(.:format)
  // function(options)
  lcms_engine_new_admin_resource_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"resources",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// lcms_engine.edit_admin_resource => /lcms/admin/resources/:id/edit(.:format)
  // function(id, options)
  lcms_engine_edit_admin_resource_path: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"resources",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// lcms_engine.admin_resource => /lcms/admin/resources/:id(.:format)
  // function(id, options)
  lcms_engine_admin_resource_path: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"resources",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// lcms_engine.toggle_editing_enabled_admin_settings => /lcms/admin/settings/toggle_editing_enabled(.:format)
  // function(options)
  lcms_engine_toggle_editing_enabled_admin_settings_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"toggle_editing_enabled",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// lcms_engine.reset_password_admin_user => /lcms/admin/users/:id/reset_password(.:format)
  // function(id, options)
  lcms_engine_reset_password_admin_user_path: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reset_password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// lcms_engine.admin_users => /lcms/admin/users(.:format)
  // function(options)
  lcms_engine_admin_users_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// lcms_engine.new_admin_user => /lcms/admin/users/new(.:format)
  // function(options)
  lcms_engine_new_admin_user_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// lcms_engine.edit_admin_user => /lcms/admin/users/:id/edit(.:format)
  // function(id, options)
  lcms_engine_edit_admin_user_path: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// lcms_engine.admin_user => /lcms/admin/users/:id(.:format)
  // function(id, options)
  lcms_engine_admin_user_path: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// lcms_engine.admin_standards => /lcms/admin/standards(.:format)
  // function(options)
  lcms_engine_admin_standards_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"standards",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// lcms_engine.edit_admin_standard => /lcms/admin/standards/:id/edit(.:format)
  // function(id, options)
  lcms_engine_edit_admin_standard_path: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"standards",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// lcms_engine.admin_standard => /lcms/admin/standards/:id(.:format)
  // function(id, options)
  lcms_engine_admin_standard_path: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"standards",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// lcms_engine.admin_sketch_compiler => /lcms/admin/sketch-compiler(.:format)
  // function(options)
  lcms_engine_admin_sketch_compiler_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"sketch-compiler",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// lcms_engine.new_admin_sketch_compiler => /lcms/admin/sketch-compiler/:version/new(.:format)
  // function(version, options)
  lcms_engine_new_admin_sketch_compiler_path: Utils.route([["version",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"sketch-compiler",false],[2,[7,"/",false],[2,[3,"version",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// lcms_engine.compile_admin_sketch_compiler => /lcms/admin/sketch-compiler/:version/compile(.:format)
  // function(version, options)
  lcms_engine_compile_admin_sketch_compiler_path: Utils.route([["version",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"sketch-compiler",false],[2,[7,"/",false],[2,[3,"version",false],[2,[7,"/",false],[2,[6,"compile",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// lcms_engine.delete_selected_admin_documents => /lcms/admin/documents/delete_selected(.:format)
  // function(options)
  lcms_engine_delete_selected_admin_documents_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"delete_selected",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// lcms_engine.reimport_selected_admin_documents => /lcms/admin/documents/reimport_selected(.:format)
  // function(options)
  lcms_engine_reimport_selected_admin_documents_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"reimport_selected",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// lcms_engine.import_status_admin_documents => /lcms/admin/documents/import_status(.:format)
  // function(options)
  lcms_engine_import_status_admin_documents_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"import_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// lcms_engine.admin_documents => /lcms/admin/documents(.:format)
  // function(options)
  lcms_engine_admin_documents_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"documents",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// lcms_engine.new_admin_document => /lcms/admin/documents/new(.:format)
  // function(options)
  lcms_engine_new_admin_document_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// lcms_engine.admin_document => /lcms/admin/documents/:id(.:format)
  // function(id, options)
  lcms_engine_admin_document_path: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// lcms_engine.delete_selected_admin_materials => /lcms/admin/materials/delete_selected(.:format)
  // function(options)
  lcms_engine_delete_selected_admin_materials_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"materials",false],[2,[7,"/",false],[2,[6,"delete_selected",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// lcms_engine.reimport_selected_admin_materials => /lcms/admin/materials/reimport_selected(.:format)
  // function(options)
  lcms_engine_reimport_selected_admin_materials_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"materials",false],[2,[7,"/",false],[2,[6,"reimport_selected",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// lcms_engine.import_status_admin_materials => /lcms/admin/materials/import_status(.:format)
  // function(options)
  lcms_engine_import_status_admin_materials_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"materials",false],[2,[7,"/",false],[2,[6,"import_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// lcms_engine.admin_materials => /lcms/admin/materials(.:format)
  // function(options)
  lcms_engine_admin_materials_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"materials",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// lcms_engine.new_admin_material => /lcms/admin/materials/new(.:format)
  // function(options)
  lcms_engine_new_admin_material_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"materials",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// lcms_engine.admin_material => /lcms/admin/materials/:id(.:format)
  // function(id, options)
  lcms_engine_admin_material_path: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"materials",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// lcms_engine.children_admin_curriculum => /lcms/admin/curriculum/children(.:format)
  // function(options)
  lcms_engine_children_admin_curriculum_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"curriculum",false],[2,[7,"/",false],[2,[6,"children",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// lcms_engine.edit_admin_curriculum => /lcms/admin/curriculum/edit(.:format)
  // function(options)
  lcms_engine_edit_admin_curriculum_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"curriculum",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// lcms_engine.admin_curriculum => /lcms/admin/curriculum(.:format)
  // function(options)
  lcms_engine_admin_curriculum_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"curriculum",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// lcms_engine.admin_access_codes => /lcms/admin/access_codes(.:format)
  // function(options)
  lcms_engine_admin_access_codes_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"access_codes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// lcms_engine.new_admin_access_code => /lcms/admin/access_codes/new(.:format)
  // function(options)
  lcms_engine_new_admin_access_code_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"access_codes",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// lcms_engine.edit_admin_access_code => /lcms/admin/access_codes/:id/edit(.:format)
  // function(id, options)
  lcms_engine_edit_admin_access_code_path: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"access_codes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// lcms_engine.admin_access_code => /lcms/admin/access_codes/:id(.:format)
  // function(id, options)
  lcms_engine_admin_access_code_path: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"access_codes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// lcms_engine.new_admin_batch_reimport => /lcms/admin/batch_reimport/new(.:format)
  // function(options)
  lcms_engine_new_admin_batch_reimport_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"batch_reimport",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// lcms_engine.admin_batch_reimport => /lcms/admin/batch_reimport(.:format)
  // function(options)
  lcms_engine_admin_batch_reimport_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"batch_reimport",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// lcms_engine.oauth2callback => /lcms/oauth2callback(.:format)
  // function(options)
  lcms_engine_oauth2callback_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[6,"oauth2callback",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// lcms_engine.show_with_slug => /lcms/*slug(.:format)
  // function(slug, options)
  lcms_engine_show_with_slug_path: Utils.route([["slug",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]],[2,[5,[3,"slug",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// lcms_engine.root => /lcms/
  // function(options)
  lcms_engine_root_path: Utils.route([], {}, [2,[2,[7,"/",false],[6,"lcms",false]],[7,"/",false]]),
// lcms_root => /lcms(.:format)
  // function(options)
  lcms_root_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lcms",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// new_rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/new(.:format)
  // function(options)
  new_rails_conductor_inbound_email_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_rails_conductor_inbound_email_source => /rails/conductor/action_mailbox/inbound_emails/sources/new(.:format)
  // function(options)
  new_rails_conductor_inbound_email_source_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"sources",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// new_user_password => /password/new(.:format)
  // function(options)
  new_user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_user_registration => /register/sign_up(.:format)
  // function(options)
  new_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"register",false],[2,[7,"/",false],[2,[6,"sign_up",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_user_session => /login(.:format)
  // function(options)
  new_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"login",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// oauth2callback => /oauth2callback(.:format)
  // function(options)
  oauth2callback_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"oauth2callback",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// ocx_export_lcms_admin_bundle => /lcms/admin/bundles/:id/ocx_export(.:format)
  // function(id, options)
  ocx_export_lcms_admin_bundle_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lcms",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"bundles",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"ocx_export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// ocx_status_lcms_admin_bundles => /lcms/admin/bundles/ocx_status(.:format)
  // function(options)
  ocx_status_lcms_admin_bundles_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lcms",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"bundles",false],[2,[7,"/",false],[2,[6,"ocx_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// odell_compiler => /lcms/admin/odell-compiler
  // function(options)
  odell_compiler_path: Utils.route([], {}, [2,[7,"/",false],[2,[6,"lcms",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[6,"odell-compiler",false]]]]]]),
// odell_compiler.compile_compiler => /lcms/admin/odell-compiler/compiler/compile(.:format)
  // function(options)
  odell_compiler_compile_compiler_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[2,[6,"lcms",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[6,"odell-compiler",false]]]]]],[7,"/",false]],[2,[6,"compiler",false],[2,[7,"/",false],[2,[6,"compile",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// odell_compiler.new_compiler => /lcms/admin/odell-compiler/compiler/new(.:format)
  // function(options)
  odell_compiler_new_compiler_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[2,[6,"lcms",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[6,"odell-compiler",false]]]]]],[7,"/",false]],[2,[6,"compiler",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// odell_compiler.import_metadata_templates => /lcms/admin/odell-compiler/templates/import-metadata(.:format)
  // function(options)
  odell_compiler_import_metadata_templates_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[2,[6,"lcms",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[6,"odell-compiler",false]]]]]],[7,"/",false]],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"import-metadata",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// odell_compiler.import_template_templates => /lcms/admin/odell-compiler/templates/import-template(.:format)
  // function(options)
  odell_compiler_import_template_templates_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[2,[6,"lcms",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[6,"odell-compiler",false]]]]]],[7,"/",false]],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"import-template",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// odell_compiler.import_directory_templates => /lcms/admin/odell-compiler/templates/import-directory(.:format)
  // function(options)
  odell_compiler_import_directory_templates_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[2,[6,"lcms",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[6,"odell-compiler",false]]]]]],[7,"/",false]],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"import-directory",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// odell_compiler.preview_templates => /lcms/admin/odell-compiler/templates/preview/:type(.:format)
  // function(type, options)
  odell_compiler_preview_templates_path: Utils.route([["type",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[2,[6,"lcms",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[6,"odell-compiler",false]]]]]],[7,"/",false]],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"preview",false],[2,[7,"/",false],[2,[3,"type",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// odell_compiler.reimport_template => /lcms/admin/odell-compiler/templates/:id/reimport(.:format)
  // function(id, options)
  odell_compiler_reimport_template_path: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[2,[6,"lcms",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[6,"odell-compiler",false]]]]]],[7,"/",false]],[2,[6,"templates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reimport",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// odell_compiler.templates => /lcms/admin/odell-compiler/templates(.:format)
  // function(options)
  odell_compiler_templates_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[2,[6,"lcms",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[6,"odell-compiler",false]]]]]],[7,"/",false]],[2,[6,"templates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// odell_compiler.edit_template => /lcms/admin/odell-compiler/templates/:id/edit(.:format)
  // function(id, options)
  odell_compiler_edit_template_path: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[2,[6,"lcms",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[6,"odell-compiler",false]]]]]],[7,"/",false]],[2,[6,"templates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// odell_compiler.template => /lcms/admin/odell-compiler/templates/:id(.:format)
  // function(id, options)
  odell_compiler_template_path: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[2,[6,"lcms",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[6,"odell-compiler",false]]]]]],[7,"/",false]],[2,[6,"templates",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// odell_compiler.root => /lcms/admin/odell-compiler/
  // function(options)
  odell_compiler_root_path: Utils.route([], {}, [2,[2,[7,"/",false],[2,[6,"lcms",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[6,"odell-compiler",false]]]]]],[7,"/",false]]),
// password => /password(.:format)
  // function(options)
  password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// pre_created_docs_lcms_admin_material => /lcms/admin/materials/:id/pre_created_docs(.:format)
  // function(id, options)
  pre_created_docs_lcms_admin_material_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lcms",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"materials",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"pre_created_docs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// preview_gdoc_lcms_admin_material => /lcms/admin/materials/:id/preview_gdoc(.:format)
  // function(id, options)
  preview_gdoc_lcms_admin_material_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lcms",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"materials",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"preview_gdoc",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// preview_lcms_admin_material => /lcms/admin/materials/:id/preview(.:format)
  // function(id, options)
  preview_lcms_admin_material_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lcms",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"materials",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"preview",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// preview_pdf_lcms_admin_material => /lcms/admin/materials/:id/preview_pdf(.:format)
  // function(id, options)
  preview_pdf_lcms_admin_material_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lcms",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"materials",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"preview_pdf",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// program_guide => /program_guide(.:format)
  // function(options)
  program_guide_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"program_guide",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// rails_blob_representation => /rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format)
  // function(signed_blob_id, variation_key, filename, options)
  rails_blob_representation_path: Utils.route([["signed_blob_id",true],["variation_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"representations",false],[2,[7,"/",false],[2,[6,"redirect",false],[2,[7,"/",false],[2,[3,"signed_blob_id",false],[2,[7,"/",false],[2,[3,"variation_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// rails_blob_representation_proxy => /rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format)
  // function(signed_blob_id, variation_key, filename, options)
  rails_blob_representation_proxy_path: Utils.route([["signed_blob_id",true],["variation_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"representations",false],[2,[7,"/",false],[2,[6,"proxy",false],[2,[7,"/",false],[2,[3,"signed_blob_id",false],[2,[7,"/",false],[2,[3,"variation_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/:id(.:format)
  // function(id, options)
  rails_conductor_inbound_email_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_conductor_inbound_email_reroute => /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)
  // function(inbound_email_id, options)
  rails_conductor_inbound_email_reroute_path: Utils.route([["inbound_email_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[3,"inbound_email_id",false],[2,[7,"/",false],[2,[6,"reroute",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_conductor_inbound_email_sources => /rails/conductor/action_mailbox/inbound_emails/sources(.:format)
  // function(options)
  rails_conductor_inbound_email_sources_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"sources",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_conductor_inbound_emails => /rails/conductor/action_mailbox/inbound_emails(.:format)
  // function(options)
  rails_conductor_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_direct_uploads => /rails/active_storage/direct_uploads(.:format)
  // function(options)
  rails_direct_uploads_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"direct_uploads",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rails_disk_service => /rails/active_storage/disk/:encoded_key/*filename(.:format)
  // function(encoded_key, filename, options)
  rails_disk_service_path: Utils.route([["encoded_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"disk",false],[2,[7,"/",false],[2,[3,"encoded_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_mailgun_inbound_emails => /rails/action_mailbox/mailgun/inbound_emails/mime(.:format)
  // function(options)
  rails_mailgun_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mailgun",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"mime",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_mandrill_inbound_emails => /rails/action_mailbox/mandrill/inbound_emails(.:format)
  // function(options)
  rails_mandrill_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mandrill",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_mandrill_inbound_health_check => /rails/action_mailbox/mandrill/inbound_emails(.:format)
  // function(options)
  rails_mandrill_inbound_health_check_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mandrill",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_postmark_inbound_emails => /rails/action_mailbox/postmark/inbound_emails(.:format)
  // function(options)
  rails_postmark_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"postmark",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_relay_inbound_emails => /rails/action_mailbox/relay/inbound_emails(.:format)
  // function(options)
  rails_relay_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"relay",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_sendgrid_inbound_emails => /rails/action_mailbox/sendgrid/inbound_emails(.:format)
  // function(options)
  rails_sendgrid_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"sendgrid",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_service_blob => /rails/active_storage/blobs/redirect/:signed_id/*filename(.:format)
  // function(signed_id, filename, options)
  rails_service_blob_path: Utils.route([["signed_id",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"blobs",false],[2,[7,"/",false],[2,[6,"redirect",false],[2,[7,"/",false],[2,[3,"signed_id",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// rails_service_blob_proxy => /rails/active_storage/blobs/proxy/:signed_id/*filename(.:format)
  // function(signed_id, filename, options)
  rails_service_blob_proxy_path: Utils.route([["signed_id",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"blobs",false],[2,[7,"/",false],[2,[6,"proxy",false],[2,[7,"/",false],[2,[3,"signed_id",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// redirect_to_lesson => /redirect-to-lesson/:id(.:format)
  // function(id, options)
  redirect_to_lesson_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"redirect-to-lesson",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// reference_guide_lcms_admin_bundles => /lcms/admin/bundles/reference_guide(.:format)
  // function(options)
  reference_guide_lcms_admin_bundles_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lcms",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"bundles",false],[2,[7,"/",false],[2,[6,"reference_guide",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// reference_guide_status_lcms_admin_bundles => /lcms/admin/bundles/reference_guide_status(.:format)
  // function(options)
  reference_guide_status_lcms_admin_bundles_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lcms",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"bundles",false],[2,[7,"/",false],[2,[6,"reference_guide_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// register => /register(.:format)
  // function(options)
  register_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"register",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// root => /
  // function(options)
  root_path: Utils.route([], {}, [7,"/",false]),
// student_book_lcms_admin_bundle => /lcms/admin/bundles/:id/student_book(.:format)
  // function(id, options)
  student_book_lcms_admin_bundle_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lcms",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"bundles",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"student_book",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// student_book_preview_lcms_admin_bundle => /lcms/admin/bundles/:id/preview/student_book(.:format)
  // function(id, options)
  student_book_preview_lcms_admin_bundle_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lcms",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"bundles",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"preview",false],[2,[7,"/",false],[2,[6,"student_book",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// student_book_status_lcms_admin_bundles => /lcms/admin/bundles/student_book_status(.:format)
  // function(options)
  student_book_status_lcms_admin_bundles_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lcms",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"bundles",false],[2,[7,"/",false],[2,[6,"student_book_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// teacher_book_lcms_admin_bundle => /lcms/admin/bundles/:id/teacher_book(.:format)
  // function(id, options)
  teacher_book_lcms_admin_bundle_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lcms",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"bundles",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"teacher_book",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// teacher_book_preview_lcms_admin_bundle => /lcms/admin/bundles/:id/preview/teacher_book(.:format)
  // function(id, options)
  teacher_book_preview_lcms_admin_bundle_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lcms",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"bundles",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"preview",false],[2,[7,"/",false],[2,[6,"teacher_book",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// teacher_book_status_lcms_admin_bundles => /lcms/admin/bundles/teacher_book_status(.:format)
  // function(options)
  teacher_book_status_lcms_admin_bundles_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lcms",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"bundles",false],[2,[7,"/",false],[2,[6,"teacher_book_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// unit_reader_status_lcms_admin_bundles => /lcms/admin/bundles/unit_reader_status(.:format)
  // function(options)
  unit_reader_status_lcms_admin_bundles_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lcms",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"bundles",false],[2,[7,"/",false],[2,[6,"unit_reader_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// update_rails_disk_service => /rails/active_storage/disk/:encoded_token(.:format)
  // function(encoded_token, options)
  update_rails_disk_service_path: Utils.route([["encoded_token",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"disk",false],[2,[7,"/",false],[2,[3,"encoded_token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_password => /password(.:format)
  // function(options)
  user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// user_registration => /register(.:format)
  // function(options)
  user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"register",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// user_session => /login(.:format)
  // function(options)
  user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"login",false],[1,[2,[8,".",false],[3,"format",false]],false]]])}
;
      routes.configure = function(config) {
        return Utils.configure(config);
      };
      routes.config = function() {
        return Utils.config();
      };
      routes.default_serializer = function(object, prefix) {
        return Utils.default_serializer(object, prefix);
      };
      return Object.assign({
        "default": routes
      }, routes);
    }
  };

  result = Utils.make();

  if (typeof define === "function" && define.amd) {
    define([], function() {
      return result;
    });
  } else if (typeof module !== "undefined" && module !== null) {
    try {
      module.exports = result;
    } catch (error1) {
      error = error1;
      if (error.name !== 'TypeError') {
        throw error;
      }
    }
  } else {
    Utils.namespace(this, null, result);
  }

  return result;

}).call(this);

